import { PropostalStatus } from "../types/propostals";


export interface WANumberResult{
    sender_number:string,
    totalValue:number,
    numberOfPropostals:number,
}

export interface PropostalResultByDDD{
    DDD:string,
    totalValue:number,
    numberOfPropostals:number,
}

export interface ConsultInfo{
    login:string,
    provider:string,
    createdAt:string,
}
export interface PropostalInfo{
    client_withdrawn_value:number,
    createdAt:string,
    sender_number:string,
    clientDDD:string,
    propostal_id:string,
    status:PropostalStatus,
    table_fee_id:string,
}

export enum TableFeeId{
    cometa='61c9fb2f-c902-4992-b8f5-b0ee368c45b0',
    normal='cb563029-ba93-4b53-8d53-4ac145087212',
    turbo='8beaa78b-b7ba-4f48-853e-de9f322be42f',
    acelera='f6d779ed-52bf-42f2-9dbc-3125fe6491ba',
    podium='755a9628-9438-4573-a44d-8bbb00e2d105',
}

export function getTableNameByID(id:string){
    const keys=Object.keys(TableFeeId);
    const values=Object.values(TableFeeId);
    
    for(let i=0;i<values.length;i++){
         if(values[i]==id)
             return keys[i];
    }
    return "Outras";
 }